/* width */
.aiware-el ::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
.aiware-el ::-webkit-scrollbar-track:hover {
  opacity: 0.1 !important;
  background: #ffffff;
}

/* Handle */
.aiware-el ::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  border-radius: 3px !important;
}
